/* eslint-disable @angular-eslint/directive-selector */
import { Directive, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewContainerRef } from '@angular/core';
import { isPlatformServer } from '@angular/common';

/* Directive for server side redendering,
it ensures which templates are renderer server-side on those that should be renderer only in the browser
This directive only creates the view on browser
 */
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[appShellNoRender]',
  standalone: true,
})
export class AppShellNoRenderDirective implements OnInit {
  constructor(
    @Inject(PLATFORM_ID) private platformId: NonNullable<unknown>,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit() {
    if (isPlatformServer(this.platformId)) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
